import React from 'react'
import LayoutPublic from "../components/layouts/layoutPublic"

import { graphql, Link as GatsbyLink } from "gatsby";
import StructuredBreadcrumbs from "../components/StructuredData/breadcrumbs"

import {
    Box,
    Flex,
    Image,
    SimpleGrid,
    Text
} from "@chakra-ui/react";
import Seo from '../components/Seo/';

const siteConfig = require('../../config')
const SellersTemplate = (props) => {
    // }
    const { sellers } = props.data
    const title = {
        "fr": "Les vendeurs officiels"
        ,
        "en": "Our officials sellers"
    }
    const description = {
        "fr": "Voici les vendeurs officiels de Wanaboat.fr, les professionnels à qui vous pouvez confier votre recherche d'un dériveur ou catamaran."
        ,
        "en": "Here is the official sellers. Those you can ask if you are looking for a dinghy or a catamaran."
    };
    return (
        <LayoutPublic lang={process.env.GATSBY_LANG}>
            <Seo
                title={
                    title[process.env.GATSBY_LANG]
                }
                description={
                    description[process.env.GATSBY_LANG]

                }
                slug={siteConfig.links.sellersHub[process.env.GATSBY_LANG]}
            />
            <Box mx={{ base: 0, md: "2rem", lg: "2rem", xl: "5rem", xxl: "10rem" }}>
                <StructuredBreadcrumbs
                    items={[
                        { "name": process.env.GATSBY_LANG === 'fr' ? "Capitainerie" : "Harbour office", "slug": siteConfig.links.about[process.env.GATSBY_LANG] },
                        { "name": "Vendeurs", slug: siteConfig.links.sellersHub[process.env.GATSBY_LANG] }
                    ]
                    }
                />
                <SimpleGrid my="2rem" columns={{ base: 1, lg: 3 }} gap="1rem" >
                    {sellers.edges.map((item, i) =>
                        <Flex
                            background="white"
                            key={item.node.id}
                            p=".5rem"
                            as={GatsbyLink}
                            to={`${item.node.slug}`}
                            boxShadow="sm"
                            alignItems="center"
                        >
                            {item.node.logo && item.node.logo.file ?
                                <Box>
                                    <Image mr="1rem" size="50px" h='auto' src={item.node.logo.file.url} alt={`${item.node.name} annonces sur Wanaboat`} />
                                </Box>
                                : null}
                            <Text>
                                {item.node.name}
                            </Text>

                        </Flex>
                    )}
                </SimpleGrid>
            </Box>

            {/* <Header
                // illuUrl={illu.file.url}
                name={"Vendeurs Wanaboat.fr"}
                breadcrumbsItems={[
                    { "name": "Capitainerie", "slug": "capitainerie/" },
                    { "name": "Vendeurs", slug: 'capitainerie/vendeurs/' }
                ]
                }
                h1={'Vendeurs de la Capitainerie'}
                slug={'capitainerie/vendeurs/'}
                descShort={null}
                desc={"Les professionnels qui mettent en vente sur Wanaboat.fr"}
            /> */}


        </LayoutPublic>
    )
}

export default SellersTemplate

export const pageQuery = graphql`
query sellersQuery ( $lang: String! ){
	sellers: allContentfulSeller(
        filter : { node_locale : { eq : $lang }}
    ) {
		edges {
			node {
                slug
				name
				phone
				illu {
					file {
						url
					}
				}
				logo {
					file {
						url
					}
				}
				#hours {
				#	childMarkdownRemark {
				#		html
				#		htmlAst
				#	}
				#}
				#desc {
				#	childMarkdownRemark {
				#		html
				#		htmlAst
				#	}
				#}
				location {
					lat
					lon
				}
				ad {
					id
				}
			}
		}
	}
}

`